import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Leads from "./pages/Leads"
import ThankYou from "./pages/ThankYou";
import Layout from "./components/Layout"
import Dashboard from "./pages/Dashboard";
import Test from "./pages/Test";

export default function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={<Layout />}
      >
        <Route
          index
          element={<Home />}
        />
        <Route
          path="/sobre"
          element={<About />}
        />
        <Route
          path="/contato"
          element={<Contact />}
        />
        <Route
          path="/obrigado"
          element={<ThankYou />}
        />
        <Route
          path="/leads"
          element={<Leads />}
        />
        <Route
          path="/login"
          element={<Login />}
        />
        <Route
          path="/dashboard"
          element={<Dashboard />}
        />
        <Route
          path="/test"
          element={<Test />}
        />
      </Route>
    </Routes>

  );
}