import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { db } from '../firebaseConfig'
import {
    collection,
    addDoc,
    serverTimestamp
} from 'firebase/firestore'
/* import emailjs from '@emailjs/browser'; */

export default function Form() {

    const clientsCollectionRef = collection(db, "general_clients")
    const navigate = useNavigate()

    const [nome, setNome] = useState("")
    const [email, setEmail] = useState("")
    const [telefone, setTelefone] = useState("")
    const [mensagem, setMensagem] = useState("")

    // CREATE OPERATION ON FIREBASE
    const createLead = async () => {
        await addDoc(clientsCollectionRef, {
            name: nome,
            email: email,
            telephone: Number(telefone),
            message: mensagem,
            type: "geral",
            analisado: Boolean(false),
            timestamp: serverTimestamp()
        })

        /* var templateParams = {
            nome: nome,
            telefone: telefone,
            consumo: consumo,
            ligacao: ligacao,
            cidade: cidade,
            telhado: telhado
        };

        emailjs.send(
            "service_njwb7l8",
            "template_ke7aq7s",
            templateParams,
            "flOxiQWj-5wAaa8jb"
        ).then(function (response) {
            console.log('SUCCESS!', response.status, response.text);
        }, function (error) {
            console.log('FAILED...', error);
        }); */

        navigate("/obrigado")
    }

    return (
        <div>
            <div className='form-header'>
                <h2>ENTRE EM CONTATO</h2><br></br>
            </div>
            <div
                className='form-2'
                id='form'
            >
                <label
                    className="text-input-label-2"
                    htmlFor="nome"
                >
                    Nome completo:<span className='asterisco'> *</span>
                </label>
                <input
                    className='text-input'
                    id='nome'
                    name='nome'
                    onChange={(event) => { setNome(event.target.value) }}
                    placeholder='Digite seu nome aqui'
                    value={nome}
                    required
                ></input><br></br>
                <label
                    className="text-input-label-2"
                    htmlFor="email"
                >
                    Email:<span className='asterisco'> *</span>
                </label>
                <input
                    className='text-input'
                    id='email'
                    name='email'
                    onChange={(event) => { setEmail(event.target.value) }}
                    placeholder='Digite seu email aqui'
                    value={email}
                    required
                ></input><br></br>
                <label
                    className="text-input-label-2"
                    htmlFor="telefone"
                >
                    Telefone (DDD + número):<span className='asterisco'> *</span>
                </label>
                <input
                    type='number'
                    className='text-input'
                    id='telefone'
                    name='telefone'
                    onChange={(event) => { setTelefone(event.target.value) }}
                    placeholder='Digite seu telefone aqui'
                    value={telefone}
                    required
                ></input><br></br>
                <label
                    for="textarea"
                    className="text-input-label-2"
                >
                    Escreva uma mensagem:
                </label>
                <textarea
                    className='form-textarea'
                    id="textarea"
                    name="textarea"
                    rows="5"
                    placeholder='Digite sua mensagem...'
                    onChange={(event) => { setMensagem(event.target.value) }}
                    value={mensagem}
                >
                </textarea>
                <button
                    className='submit-button'
                    type='button'
                    onClick={createLead}
                >
                    Enviar
                </button>
                <div className='form-privacy'>Sua privacidade é importante para nós!</div>
                <div className='form-privacy'>Seus dados estão protegidos conforme a LGPD (Lei Geral de Proteção de Dados).</div>
            </div>
        </div>
    )
}
