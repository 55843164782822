import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { db } from '../firebaseConfig'
import {
    collection,
    addDoc,
    serverTimestamp
} from 'firebase/firestore'
import emailjs from '@emailjs/browser';

export default function Form() {

    const clientsCollectionRef = collection(db, "clients")
    const navigate = useNavigate()

    const [nome, setNome] = useState("")
    const [telefone, setTelefone] = useState("")
    const [consumo, setConsumo] = useState("")
    const [ligacao, setLigacao] = useState("")
    const [cidade, setCidade] = useState("")
    const [telhado, setTelhado] = useState("")

    // CREATE OPERATION ON FIREBASE
    const createLead = async () => {
        await addDoc(clientsCollectionRef, {
            name: nome,
            telephone: Number(telefone),
            spending: Number(consumo),
            connection: ligacao,
            city: cidade,
            roof: telhado,
            type: "solar",
            analisado: Boolean(false),
            timestamp: serverTimestamp()
        })

        var templateParams = {
            nome: nome,
            telefone: telefone,
            consumo: consumo,
            ligacao: ligacao,
            cidade: cidade,
            telhado: telhado
        };

        emailjs.send(
            "service_njwb7l8",
            "template_ke7aq7s",
            templateParams,
            "flOxiQWj-5wAaa8jb"
        ).then(function (response) {
            console.log('SUCCESS!', response.status, response.text);
        }, function (error) {
            console.log('FAILED...', error);
        });

        navigate("/obrigado")
    }

    return (
        <div>
            <div className='form-header'>
                <h2>Economize até 85% na conta de luz!</h2><br></br>
                <h4>Você gasta mais de R$ 400,00 mensais com energia elétrica? Solicite seu orçamento gratuito de energia solar!</h4>
            </div>
            <div
                className='form'
                id='form'
            >
                <label
                    className="text-input-label"
                    htmlFor="nome"
                >
                    Nome completo:<span className='asterisco'> *</span>
                </label>
                <input
                    className='text-input'
                    id='nome'
                    name='nome'
                    onChange={(event) => { setNome(event.target.value) }}
                    placeholder='Digite seu nome aqui'
                    value={nome}
                    required
                ></input><br></br>
                <label
                    className="text-input-label"
                    htmlFor="telefone"
                >
                    Telefone (DDD + número):<span className='asterisco'> *</span>
                </label>
                <input
                    type='number'
                    className='text-input'
                    id='telefone'
                    name='telefone'
                    onChange={(event) => { setTelefone(event.target.value) }}
                    placeholder='Digite seu telefone aqui'
                    value={telefone}
                    required
                ></input><br></br>
                <label
                    className="text-input-label"
                    htmlFor="consumo"
                >
                    Consumo médio em reais (R$):<span className='asterisco'> *</span>
                </label>
                <input
                    className='text-input'
                    id='consumo'
                    name='consumo'
                    onChange={(event) => { setConsumo(event.target.value) }}
                    placeholder='Digite seu consumo em R$ aqui'
                    value={consumo}
                    required
                ></input><br></br>
                <p>Tipo de ligação:<span className='asterisco'> *</span></p>
                <input
                    type="radio"
                    name="ligacao"
                    value="Monofásica"
                    id="monofasica"
                    onChange={(event) => { setLigacao(event.target.value) }}
                />
                <label
                    className="input-radio-label"
                    htmlFor="monofasica"
                >
                    Monofásica
                </label><br></br>
                <input
                    type="radio"
                    name="ligacao"
                    value="Bifásica"
                    id="bifasica"
                    onChange={(event) => { setLigacao(event.target.value) }}
                />
                <label
                    className="input-radio-label"
                    htmlFor="bifasica"
                >
                    Bifásica
                </label><br></br>
                <input
                    type="radio"
                    name="ligacao"
                    value="Trifásica"
                    id="trifasica"
                    onChange={(event) => { setLigacao(event.target.value) }}
                />
                <label
                    className="input-radio-label"
                    htmlFor="trifasica"
                >
                    Trifásica
                </label><br></br>
                <input
                    type="radio"
                    name="ligacao"
                    value="Não sei informar"
                    id="nao-sei-informar"
                    onChange={(event) => { setLigacao(event.target.value) }}
                />
                <label
                    className="input-radio-label"
                    htmlFor="nao-sei-informar"
                >
                    Não sei informar
                </label><br></br>
                <label
                    className="text-input-label"
                    htmlFor="cidade"
                >
                    Cidade:<span className='asterisco'> *</span>
                </label>
                <input
                    className='text-input'
                    id='cidade'
                    name='cidade'
                    onChange={(event) => { setCidade(event.target.value) }}
                    placeholder='Digite sua cidade aqui'
                    value={cidade}
                    required
                ></input><br></br>
                <p>Tipo de telhado:<span className='asterisco'> *</span></p>
                <input
                    type="radio"
                    name="telhado"
                    value="Colonial | Cerâmico"
                    id="colonial"
                    onChange={(event) => { setTelhado(event.target.value) }}
                />
                <label
                    className="input-radio-label"
                    htmlFor="colonial"
                >
                    Colonial - Cerâmico
                </label><br></br>
                <input
                    type="radio"
                    name="telhado"
                    value="Fibrocimento"
                    id="fibrocimento"
                    onChange={(event) => { setTelhado(event.target.value) }}
                />
                <label
                    className="input-radio-label"
                    htmlFor="fibrocimento"
                >
                    Fibrocimento
                </label><br></br>
                <input
                    type="radio"
                    name="telhado"
                    value="Metálico"
                    id="metalico"
                    onChange={(event) => { setTelhado(event.target.value) }}
                />
                <label
                    className="input-radio-label"
                    htmlFor="metalico"
                >
                    Metálico
                </label><br></br>
                <input
                    type="radio"
                    name="telhado"
                    value="Laje"
                    id="laje"
                    onChange={(event) => { setTelhado(event.target.value) }}
                />
                <label
                    className="input-radio-label"
                    htmlFor="laje"
                >
                    Laje
                </label><br></br>
                <input
                    type="radio"
                    name="telhado"
                    value="Solo"
                    id="solo"
                    onChange={(event) => { setTelhado(event.target.value) }}
                />
                <label
                    className="input-radio-label"
                    htmlFor="solo"
                >
                    Solo
                </label><br></br>
                <button
                    className='submit-button'
                    type='button'
                    onClick={createLead}
                >
                    Enviar pedido de orçamento
                </button>
                <div className='form-privacy'>Sua privacidade é importante para nós!</div>
                <div className='form-privacy'>Seus dados estão protegidos conforme a LGPD (Lei Geral de Proteção de Dados).</div>
            </div>
        </div>
    )
}
