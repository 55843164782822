import React from 'react'
import GeneralForm from '../components/GeneralForm'

export default function Contato() {
  return (
    <div>
      <div className='contact-info'>
                <GeneralForm />
            </div>
    </div>
  )
}
