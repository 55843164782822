import React from 'react'
import about_1 from '../assets/images/about_1.jpg'

export default function Sobre() {
    return (
        <div>
            <div className='about-info'>
                <h3>Somos a Condus Elétrica</h3>
                <p>Atuamos no mercado baiano desde 2020. Nossos serviços incluem instalação, limpeza, manutenção e projetos de sistemas fotovoltaicos (energia solar). Possuímos <strong>mais de 150 projetos instalados e homologados</strong> pela concessionária COELBA em toda Bahia.</p>
                <img
                    src={about_1}
                    alt='Energia solar'
                    className='about-image'
                />
                <p>Além disso, trabalhamos com <strong>instalações elétricas residenciais, prediais e comerciais</strong>. Portanto, se você precisa trocar a fiação de uma instalação antiga ou fazer uma instalação do zero, nós estamos aqui para te ajudar!</p>
                <p>Entre em <a href='/contato'>contato</a> conosco e venha fazer parte de nossa história!</p>
            </div>
        </div>
    )
}
