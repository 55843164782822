import React from 'react'

export default function Footer() {

  const date = new Date()
  const year = date.getFullYear()

  return (
    <footer>
      <div className='footer-1'>
        <p>Condus Elétrica &#45; Todos os direitos reservados.</p>
        <p>CONDUS ELÉTRICA LTDA &#45; CNPJ: 38.273.634/0001-90</p>
        <p>Avenida Luís Viana Filho, 7532 &#45; Conj. Helbor &#45; Torre 02 | Alphaville I | Salvador &#45; Bahia | CEP: 41.701-005</p>
      </div>
      <div className='footer-2'>
        <p>Site desenvolvido pela <a href='https://antares-code.netlify.app/'>Antares Code 94</a></p>
        <p>© Copyright &#45; {year}</p>
      </div>
    </footer>
  )
}
