import React from 'react'
import logo from "../assets/images/logo.png"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useEffect, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';

export default function Header() {

  const [authenticatedUser, setAuthenticatedUser] = useState("")

  useEffect(() => {
    const listenAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticatedUser(user)
      } else {
        setAuthenticatedUser(null)
      }
    })
    return () => {
      listenAuth()
    }
  }, [])

  /* SIGN OUT OPERATION */
  const userSignOut = () => {
    signOut(auth).then(() => {
      console.log("")
    }).catch(error => console.log("Error!"))
  }

  return (
    <header>
      <Navbar expand="lg" className="container-navbar-root">
        <Container className='container-navbar'>
          <Navbar.Brand href="/"><img
            src={logo}
            alt='Logo da empresa'
            className='navbar-logo'
          /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className='bg-body' />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto text-center">
              <Nav.Link
                href="/"
                className='nav-link-text'
              >
                Home
              </Nav.Link>
              <Nav.Link
                href="/contato"
                className='nav-link-text'
              >
                Contato
              </Nav.Link>
              <Nav.Link
                href="/sobre"
                className='nav-link-text'
              >
                Sobre
              </Nav.Link>
              {
                authenticatedUser === null ?
                  <Nav.Link
                    href="/login"
                    className='nav-link-text'
                  >
                    Login
                  </Nav.Link> :
                  <>
                    <Nav.Link
                      href="/leads"
                      className='nav-link-text'
                    >
                      Leads
                    </Nav.Link>
                    <Nav.Link
                      href="/dashboard"
                      className='nav-link-text'
                    >
                      Dashboard
                    </Nav.Link>
                    <Nav.Link
                      href="/test"
                      className='nav-link-text'
                    >
                      Test
                    </Nav.Link>
                    <Nav.Link
                      href="/login"
                      className='nav-link-text'
                      onClick={userSignOut}
                    >
                      Sair
                    </Nav.Link>
                  </>
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}
