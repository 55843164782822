import React from 'react'
import Form from '../components/Form'
/* import Video from '../components/Video' */
import bars from '../assets/images/bars.jpg'
import money from '../assets/images/money.png'
import house from '../assets/images/house.png'
import tools from '../assets/images/tools.png'
import ca_1 from '../assets/images/ca_1.png'
import ca_2 from '../assets/images/ca_2.png'
import ca_3 from '../assets/images/ca_3.png'
import ca_4 from '../assets/images/ca_4.png'
import ca_5 from '../assets/images/ca_5.png'
import ca_6 from '../assets/images/ca_6.png'
import ca_7 from '../assets/images/ca_7.png'
import whatsapp from '../assets/images/whatsapp.png'
import instagram from '../assets/images/instagram.png'
import facebook from '../assets/images/facebook.png'
import bv from '../assets/images/bv.png'
import solfacil from '../assets/images/solfacil.png'
import sicredi from '../assets/images/sicredi.jpg'
import santander from '../assets/images/santander.png'
import sol_agora from '../assets/images/sol_agora.png'
import bradesco from '../assets/images/bradesco.png'
import top_image from '../assets/images/site_1920_768.png'
import Accordion from 'react-bootstrap/Accordion'

export default function Home() {
    return (
        <div>
            <img
                src={top_image}
                alt='Catch phrase'
                className='top-image'
            >
            </img>
            <Form />
            {/* <Video /> */}
            <hr className='hr'></hr>
            <h1 className='home-titles'>Benefícios da energia solar</h1>
            <hr className='hr'></hr>
            <div className='info-1'>
                <div>
                    <img
                        src={bars}
                        alt='Bars'
                    >
                    </img>
                    <p>Vida útil de mais de 25 anos, com retorno de investimento em até 3 anos</p>
                </div>
                <hr className='hr-2'></hr>
                <div>
                    <img
                        src={money}
                        alt='Bars'
                    >
                    </img>
                    <p>Economia de até 85% da conta de luz</p>
                </div>
                <hr className='hr-2'></hr>
                <div>
                    <img
                        src={house}
                        alt='Bars'
                    >
                    </img>
                    <p>Valoriza seu imóvel</p>
                </div>
                <hr className='hr-2'></hr>
                <div>
                    <img
                        src={tools}
                        alt='Bars'
                    >
                    </img>
                    <p>Necessidade mínima de manutenção</p>
                </div>
            </div>
            <hr className='hr'></hr>
            <h1 className='home-titles'>Conheça alguns de nossos projetos:</h1>
            <hr className='hr'></hr>
            <div className='container-carousel'>
                <div id="demo" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#demo" data-bs-slide-to="0" className="active"></button>
                        <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
                        <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
                        <button type="button" data-bs-target="#demo" data-bs-slide-to="3"></button>
                        <button type="button" data-bs-target="#demo" data-bs-slide-to="4"></button>
                        <button type="button" data-bs-target="#demo" data-bs-slide-to="5"></button>
                        <button type="button" data-bs-target="#demo" data-bs-slide-to="6"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img
                                src={ca_1}
                                alt="Cliente"
                                className="d-block"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="carousel-item">
                            <img
                                src={ca_2}
                                alt="Cliente"
                                className="d-block"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="carousel-item">
                            <img
                                src={ca_3}
                                alt="Cliente"
                                className="d-block"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="carousel-item">
                            <img
                                src={ca_4}
                                alt="Cliente"
                                className="d-block"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="carousel-item">
                            <img
                                src={ca_5}
                                alt="Cliente"
                                className="d-block"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="carousel-item">
                            <img
                                src={ca_6}
                                alt="Cliente"
                                className="d-block"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="carousel-item">
                            <img
                                src={ca_7}
                                alt="Cliente"
                                className="d-block"
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon"></span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
                        <span class="carousel-control-next-icon"></span>
                    </button>
                </div>
            </div>
            <hr className='hr'></hr>
            {/* <div className='card-client'>
                <p><strong>Nome:</strong> Editael Nascimento</p>
                <p><strong>Média da geração:</strong> 1.900 kWh/mês</p>
                <p><strong>Local da instalação:</strong> Cajazeiras X - Salvador</p>
                <p><strong>Tamanho do sistema:</strong> 14,4 kWp</p>
                <p><strong>Inversor:</strong> GROWATT MIN 10000TL-X</p>
                <p><strong>Módulos:</strong> 32 unidades da marca JINKO</p>
                <img
                    src={client_1}
                    alt='Client 1'
                />
            </div>
            <hr className='hr-2'></hr>
            <div className='card-client'>
                <p><strong>Nome:</strong> Maria Filha</p>
                <p><strong>Média da geração:</strong> 1.800 kWh/mês</p>
                <p><strong>Local da instalação:</strong> Abrantes - Camaçari</p>
                <p><strong>Tamanho do sistema:</strong> 11,8 kWp</p>
                <p><strong>Microinversor:</strong> HOYMILES HMS-2000-4T</p>
                <p><strong>Módulos:</strong> 20 unidades da marca LEAPTON SOLAR</p>
                <img
                    src={client_2}
                    alt='Client 2'
                />
            </div>
            <hr className='hr-2'></hr>
            <div className='card-client'>
                <p><strong>Nome:</strong> Sindicelpa</p>
                <p><strong>Média da geração:</strong> 1.300 kWh/mês</p>
                <p><strong>Local da instalação:</strong> Gleba A - Camaçari</p>
                <p><strong>Tamanho do sistema:</strong> 10,8 kWp</p>
                <p><strong>Inversor:</strong> GROWATT MIN8000TL-X(E)</p>
                <p><strong>Módulos:</strong> 24 unidades da marca JINKO</p>
                <img
                    src={client_3}
                    alt='Client 3'
                />
            </div>
            <hr className='hr-2'></hr>
            <div className='card-client'>
                <p><strong>Nome:</strong> Josemar</p>
                <p><strong>Média da geração:</strong> 400 kWh/mês</p>
                <p><strong>Local da instalação:</strong> Vida Nova - Lauro de Freitas</p>
                <p><strong>Tamanho do sistema:</strong> 4,44 kWp</p>
                <p><strong>Microinversor:</strong> HOYMILES HMS-1800-4T</p>
                <p><strong>Módulos:</strong> 8 unidades da marca LEAPTON</p>
                <img
                    src={client_4}
                    alt='Client 4'
                />
            </div>
            <hr className='hr-2'></hr>
            <div className='card-client'>
                <p><strong>Nome:</strong> Cláudio Andrade</p>
                <p><strong>Média da geração:</strong> 200 kWh/mês</p>
                <p><strong>Local da instalação:</strong> Morro do Chapéu</p>
                <p><strong>Tamanho do sistema:</strong> 1,84 kWp</p>
                <p><strong>Microinversor:</strong> DEYE SUN2000G3-US-220</p>
                <p><strong>Módulos:</strong> 4 unidades da marca JINKO</p>
                <img
                    src={client_5}
                    alt='Client 5'
                />
            </div>
            <hr className='hr-2'></hr>
            <div className='card-client'>
                <p><strong>Nome:</strong> Manoel Santos</p>
                <p><strong>Média da geração:</strong> 930 kWh/mês</p>
                <p><strong>Local da instalação:</strong> Phoc II - Camaçari</p>
                <p><strong>Tamanho do sistema:</strong> 8,25 kWp</p>
                <p><strong>Inversor:</strong> SUNGROW SG6.0RS</p>
                <p><strong>Módulos:</strong> 15 unidades da marca JA SOLAR</p>
                <img
                    src={client_6}
                    alt='Client 6'
                />
            </div>
            <hr className='hr-2'></hr>
            <div className='card-client'>
                <p><strong>Nome:</strong> Edgard Pattas</p>
                <p><strong>Média da geração:</strong> 833 kWh/mês</p>
                <p><strong>Local da instalação:</strong> Castelo Branco - Salvador</p>
                <p><strong>Tamanho do sistema:</strong> 7,7 kWp</p>
                <p><strong>Inversor:</strong> GROWATT MIN 6000TL-X</p>
                <p><strong>Módulos:</strong> 14 unidades da marca BELENERGY</p>
                <img
                    src={client_7}
                    alt='Client 7'
                />
            </div>
            <hr className='hr'></hr> */}
            <h1 className='home-titles'>Bancos parceiros:</h1>
            <hr className='hr'></hr>
            <div className='container-banks'>
                <img
                    className='grid-item'
                    src={bv}
                    alt='Logo da BV'
                />
                <img
                    className='grid-item'
                    src={solfacil}
                    alt='Logo da Solfácil'
                />
                <img
                    className='grid-item'
                    src={sol_agora}
                    alt='Logo da Sol Agora'
                />
                <img
                    className='grid-item'
                    src={sicredi}
                    alt='Logo da Sicredi'
                />
                <img
                    className='grid-item'
                    src={santander}
                    alt='Logo do santander'
                />
                <img
                    className='grid-item-bradesco'
                    src={bradesco}
                    alt='Logo do Bradesco'
                />
            </div>
            <hr className='hr'></hr>
            <h1 className='home-titles'>Quer saber mais?</h1>
            <hr className='hr'></hr>
            <div className='social-media'>
                <a href='#form'><button>FAÇA UM ORÇAMENTO AGORA</button></a><br></br>
                <p>Acompanhe a nossa empresa nas redes sociais e canais de relacionamento:</p>
                <div className='social-media-buttons'>
                    <a href='https://api.whatsapp.com/send/?phone=5571993328396&text&type=phone_number&app_absent=0'><img
                        src={whatsapp}
                        alt='WhatsApp logo'
                    /></a>
                    <a href='https://www.instagram.com/conduseletrica/'><img
                        src={instagram}
                        alt='Instagram logo'
                    /></a>
                    <a href='https://web.facebook.com/profile.php?id=100063819270106'><img
                        src={facebook}
                        alt='Facebook logo'
                    /></a>
                </div>
            </div>
            {/* ACCORDION */}
            <>
                <Accordion className="accordion-container">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Como funciona a energia solar?</Accordion.Header>
                        <Accordion.Body className="accordion-body">
                            A energia solar funciona através de painéis fotovoltaicos que captam a luz do sol e a transformam em eletricidade. Essa eletricidade pode ser usada para alimentar aparelhos, sistemas elétricos de casas ou empresas.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>A energia solar funciona em dias nublados?</Accordion.Header>
                        <Accordion.Body>
                            Sim, mesmo em dias nublados os painéis solares ainda captam luz solar, embora em menor quantidade. A produção de energia será reduzida, mas o sistema continuará gerando eletricidade.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Quais são os benefícios da energia solar?</Accordion.Header>
                        <Accordion.Body>
                            A energia solar é uma fonte limpa e renovável, o que significa que não emite gases de efeito estufa. Além disso, pode reduzir significativamente a conta de luz e aumentar a independência energética.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Qual é a durabilidade dos painéis solares?</Accordion.Header>
                        <Accordion.Body>
                            Em média, os painéis solares têm uma vida útil de 25 a 30 anos. Com manutenção adequada, eles podem continuar funcionando eficientemente por ainda mais tempo.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>É possível vender o excesso de energia solar?</Accordion.Header>
                        <Accordion.Body>
                            Sim, em muitos casos, o sistema de energia solar pode ser conectado à rede elétrica, e o excesso de energia gerado pode ser enviado para a distribuidora local, gerando créditos para o proprietário.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </>
            <div>
                <a
                    href="https://wa.me//5571993328396?text=Tenho%20interesse%20em%20fazer%20um%20orçamento%20de%20energia%20solar!"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src={whatsapp}
                        alt='Logo do WhatsApp'
                        className="whatsapp-button"
                    />
                </a>
            </div>
        </div>
    )
}
