import React, { useState, useEffect } from 'react'
import { auth } from '../firebaseConfig'
import { onAuthStateChanged, /* signOut */ } from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import Alert from 'react-bootstrap/Alert';

export default function Test() {

    const [authenticatedUser, setAuthenticatedUser] = useState("")
    const [image, setImage] = useState("")
    const navigate = useNavigate()
    const goToLogin = () => {
        navigate("/login")
    }

    // AUTH
    useEffect(() => {
        const listenAuth = onAuthStateChanged(auth, (user) => {
            if (user) {
                setAuthenticatedUser(user)
            } else {
                setAuthenticatedUser(null)
            }
        })
        return () => {
            listenAuth()
        }
    }, [])

    const handleImageChange = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]))
    }

    return (
        <div>
            {
                authenticatedUser === null ?
                    goToLogin() :
                    <div className='test'>
                        <h1>Área de testes</h1>
                        <Alert
                            variant="success"
                            className='clients-user'
                        >
                            Você está logado como <strong>{authenticatedUser.email}</strong>
                        </Alert>
                        <input
                            type='file'
                            onChange={handleImageChange}
                        />
                        {image ? <img
                            src={image} alt='Imagem'
                        /> : "A imagem será exibida aqui."}
                    </div>
            }
        </div>

    )
}
